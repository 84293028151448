html,
body {
    padding: 0;
    margin: 0;
    font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

main {
    background-color: #4158d0;
    background-image: linear-gradient(
        43deg,
        #4158d0 0%,
        #c850c0 46%,
        #ffcc70 100%
    );
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;

    @media only screen and (max-width: 767px) {
        min-height: 100vh;
        height: auto;
        padding: 0 0 30px 0;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    padding: 0;
    margin: 0;
    font-weight: 700;
}

p {
    font-weight: 400;
}

// CONTAINER
.container {
    max-width: 1440px;
    margin: 0 auto;

    @media only screen and (max-width: 1600px) {
        max-width: 1200px;
    }

    @media only screen and (max-width: 1364px) {
        max-width: 900px;
    }

    @media only screen and (max-width: 1024px) {
        max-width: 720px;
    }

    @media only screen and (max-width: 800px) {
        max-width: 90%;
    }
}
