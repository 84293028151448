.md-header {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 60px;

    &__title {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 60px;
        text-align: center;
        text-transform: uppercase;

        @media only screen and (max-width: 767px) {
            font-size: 30px;
        }
    }
}
